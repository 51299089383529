import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-saas',
  templateUrl: './saas.component.html',
  styleUrls: ['./saas.component.scss']
})
export class SaasComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
