<section class="singl-testimonial shadow">
  <div class="container-fluid py-0">
    <div
      class="row align-items-center gradient gradient-navy-blue text-contrast"
    >
      <div
        class="col-md-7 image-background cover center-top"
        [style.background-image]="'url(assets/img/testimonials/2.jpg)'"
      ></div>

      <div class="col-md-3 mx-auto py-4 py-md-0">
        <blockquote class="bold mt-1 text-contrast">
          <fa-icon [icon]="quoteLeft" [class]="'quote'"></fa-icon>
          If your company is struggling to stay in business, there may be
          several options you can explore.
          <span class="bold">Dashcore,</span> was the perfect template. It is
          very easy to implement, has great design, and it has all the
          functionality we were looking for.
        </blockquote>

        <hr class="my-4" />
        <div class="small text-contrast">
          <span class="bold d-block">Jean Doe,</span>
          CEO & Founder of Awesome Company
        </div>
      </div>
    </div>
  </div>
</section>
