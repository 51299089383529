<section class="section">
  <div class="container">
    <div class="section-heading text-center" style="margin-top: 72px;">
      <h2 class="bold">Módulos incluidos en HORAX3</h2>
    </div>

    <nav
      class="slide nav nav-tabs nav-outlined nav-rounded justify-content-around justify-content-md-center mb-5"
    >
      <a
        class="nav-item nav-link"
        [class.active]="i === currentSlide"
        href="javascript:;"
        (click)="updateSlider(i)"
        *ngFor="
          let item of [
            'Estado empleados',
            'Calendario',
            'Gestión documental',
            'Notificaciones',
            'Cuadrante',
            'Informes'
          ];
          let i = index
        "
      >
        {{ item }}
      </a>
    </nav>

    <swiper [index]="currentSlide" (indexChange)="onIndexChange($event)">
      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-5">
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->
              <div>
                <img
                  src="../../../assets/img/logos-grupo/users.png"
                  style="width: 65px; margin-bottom: 14px;"
                />
              </div>
              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Incluido</dc-badge> -->
              <!-- <h2
                class="display-4 light"
                style="font-size: 2.4rem !important; ">
                Estado Empleados
              </h2> -->
              <p>
                Disponga de los registros horarios semanales, mensuales, además
                de la hora y ubicación del último fichaje registrado, además
                podrá diferenciar entre diferentes estados como el tiempo de
                desayuno, descanso, comida...
              </p>
            </div>
            <div class="col-md-6 mr-md-auto">
              <div class="shadow">
                <img
                  src="assets/img/screens/dash/1.png"
                  class="img-responsive"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class=" shadow">
                <img
                  src="assets/img/screens/dash/2.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
              <img
                src="assets/img/screens/dash/2.1.png"
                alt=""
                class="absolute img-responsive shadow rounded"
                style="right: 0; top: 0"
              />
            </div>

            <div class="col-md-5 mr-md-auto">
              <div>
                <img
                  src="../../../assets/img/logos-grupo/Calendario3.png"
                  style="width: 55px; margin-bottom: 14px; "
                />
              </div>
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->
              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Destacado</dc-badge> -->
              <!-- <h2
                class="display-4 light"
                style="font-size: 2.4rem !important; ">
                Calendario
              </h2> -->
              <!-- <hr class="mt-5" /> -->
              <p>
                Gestione las vacaciones y las ausencias de sus equipos. La
                plantilla puede solicitar los días de vacaciones, asuntos
                propios o cualquier otro motivo de ausencia de su lugar de
                trabajo.
                <br />
                El sistema les notificará a los responsables dichas ausencias,
                pudiendo aprobarlas o denegarlas.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-5">
              <div>
                <img
                  src="../../../assets/img/logos-grupo/clip.png"
                  style="width: 55px; margin-bottom: 14px;"
                />
              </div>
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->
              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Esencial</dc-badge> -->
              <!-- <h2
                class="display-4 light"
                style="font-size: 2.4rem !important; ">
                Gestión Documental
              </h2> -->
              <!-- <p class="lead text-secondary">
                Módulo indispensable para cuidar del medio ambiente.
              </p> -->
              <p>
                Sus departamentos dispondran de la información para el
                tratamiento e integración automática de las nóminas, tickets,
                facturas, partes de baja y cualquier otra documentación
                necesaria.
                <br />
                <br />
                Los documentos en formato electrónico podrán ser compartidos
                según sus necesidades, con el consecuente ahorro de papel y de
                las tareas administrativas derivadas.
              </p>
            </div>
            <div class="col-md-6 mr-md-auto">
              <div class="shadow-box">
                <img
                  src="assets/img/screens/dash/3.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class="shadow">
                <img
                  src="assets/img/screens/dash/notificaciones.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>

            <div class="col-md-5">
              <div>
                <img
                  src="../../../assets/img/logos-grupo/campana.png"
                  style="width: 55px; margin-bottom: 14px;"
                />
              </div>
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->

              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Único</dc-badge> -->
              <!-- <h2 class="display-4 light" style="font-size: 2.4rem !important;">
                Notificaciones
              </h2> -->
              <p>
                La plantilla recibirá las notificaciones sobre sus horarios, sus
                obligaciones de fichar y sobre cualquier otra información que
                desee.
                <br />
                <br />
                El departamento de recursos humanos y cualquier otro
                responsable, dispondrán siempre de la información en tiempo real
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-5 mr-md-auto">
              <div>
                <img
                  src="../../../assets/img/logos-grupo/check.png"
                  style="width: 55px; margin-bottom: 14px;"
                />
              </div>
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->
              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Automatice los turnos</dc-badge > -->
              <!-- <h2 class="display-4 light" style="font-size: 2.4rem !important;">
                Cuadrante
              </h2> -->
              <!-- <p class="lead">
                Gestión de turnos de trabajo.
              </p> -->
              <!-- <hr class="mt-5" /> -->
              <p>
                La aplicación permite la gestión de los turnos de trabajo,
                además de automatizar los horarios por centros y secciones, para
                organizar de forma sencilla los turnos y editarlos si fuera
                necesario.
                <br />
                <br />
                La plantilla podrá solicitar los cambios de turno y los
                responsables los aprobarán/denegarán desde la misma aplicación.
              </p>
            </div>

            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class="shadow">
                <img
                  src="assets/img/screens/dash/2.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-5">
              <div>
                <img
                  src="../../../assets/img/logos-grupo/escribir.png"
                  style="width: 55px; margin-bottom: 14px;"
                />
              </div>
              <!-- <dc-badge [bg]="'contrast'" [text]="'primary'">Informes</dc-badge> -->
              <!-- <h2 class="display-4 light" style="font-size: 2.4rem !important;">
                Informes
              </h2> -->
              <!-- <dc-feather
                [name]="'sliders'"
                [iconClass]="'stroke-primary'"
              ></dc-feather> -->
              <p>
                La herramienta generará informes personalizables, adaptados y
                agrupados según las necesidades de cada departamento, con la
                periodicidad que se necesite en cada caso. Podrán generar
                informes diarios, semanales, mensuales, de ausencias, de
                vacaciones, de solicitudes...
              </p>
            </div>

            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class="shadow">
                <img
                  src="assets/img/screens/dash/4.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper>
  </div>
</section>
