import { Component, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FaqsService } from '../services/faqs.service';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dc-faqs-accordion',
  templateUrl: './faqs-accordion.component.html',
  styleUrls: ['./faqs-accordion.component.scss'],
  providers: [NgbAccordionConfig] // add the NgbAccordionConfig to the component providers
})
export class FaqsAccordionComponent implements OnInit {
  chevronDown = faChevronDown;
  chevronUp = faChevronUp;
  // chevron :string[] =['faChevronDown', 'faChevronDown', 'faChevronDown', 'faChevronDown'] ;

  faqs = [];

  constructor(config: NgbAccordionConfig, private faqService: FaqsService) {
    config.closeOthers = true;
  }

  ngOnInit() {
    this.faqService.getFaqs().subscribe(faqs => (this.faqs = faqs));
  }

  desplegarRespuesta(index) {
    if (this.faqs[index].estado == false) {
      this.faqs[index].estado = true;
      // this.chevron = faChevronUp;
    } else {
      this.faqs[index].estado = false;
    }
  }

  // cambioChevron(index){
  //   if(this.chevron[index] == 'faChevronUp'){
  //     this.chevron[index] = 'faChevronDown';
  //   }else{
  //     this.chevron[index] = 'faChevronUp';
  //   }
  // }
}
