<!-- <footer class="site-footer section" style="background: #f8f8ff;"> -->
<!-- <footer class="site-footer section" style="background: #00365f;"> -->
<footer class="site-footer section" style="background: #c3c9db;">
  <div class="container pb-3">
    <div class="row gap-y text-center text-md-left">
      <div class="col-md-4 mr-auto">
        <!-- <img src="assets/img/logos-horax3/logo.png" alt="" class="logo" /> -->
        <img
          style="margin-bottom: 30px;"
          src="assets/img/logos-grupo/Horax3_White.png"
          alt=""
          class="logo"
        />

        <p style="margin-top: 0px !important; ">
          La herramienta diseñada para ahorrar tiempo y dinero en la gestión de
          los registros de la jornada.
        </p>
      </div>

      <div class="col-md-3">
        <h6 class="py-2 bold" style="margin-bottom: 11px;">Menú</h6>

        <nav class="nav flex-column">
          <a class="nav-item py-2" href="/inicio">Inicio</a>
          <a class="nav-item py-2" href="/contacto">Contacto</a>
          <a class="nav-item py-2" href="/pricing">Precios</a>
        </nav>
      </div>

      <!-- <div class="col-md-2">
        <h6 class="py-2 bold">Product</h6>

        <nav class="nav flex-column">
          <a class="nav-item py-2" href="#">Features</a>
          <a class="nav-item py-2" href="#">API</a>
          <a class="nav-item py-2" href="#">Customers</a>
        </nav>
      </div> -->

      <div class="col-md-3">
        <h6 class="py-2 bold" style="margin-bottom: 11px;">Legal</h6>

        <nav class="nav flex-column">
          <!-- <a
            class="nav-item py-2"
            href="https://www.grupox3.es/politica-privacidad/"
            target="_blank"
            >Terminos y condiciones</a
          > -->
          <!-- <a
            class="nav-item py-2"
            href="https://www.grupox3.es/politica-privacidad/"
            target="_blank"
            >Políticas de privacidad</a
          > -->
          <!-- <a class="nav-item py-2" [routerLink]="['/aviso-legal']" target="_top">
            Aviso legal
          </a> -->
          <a class="nav-item py-2" href="/aviso-legal">
            Aviso legal
          </a>
          <!-- <a
            class="nav-item py-2"
            href="https://www.grupox3.es/politica-privacidad/"
            target="_blank"
            >Aviso legal</a
          > -->
        </nav>
      </div>
    </div>
    <!-- <hr class="mt-5" /> -->
  </div>

  <div class="row footer" style="padding: 20px 0px;">
    <div class="empresa">
      <h3>Una empresa de</h3>
    </div>
    <div class="logofooter">
      <img src="assets/img/logos-grupo/logoFooter.png" />
      <!-- <img src="assets/img/logos-grupo/Horax3.png" />  -->
    </div>
  </div>

  <div class="container pb-3" style="padding-top: 0px;">
    <div class="row small text-center">
      <div class="col">
        <p class="primary copyright text-secondary">
          Copyright {{ year }} HORAX3, por
          <a class="brand bold" href="https://www.grupox3.com" target="_blank"
            >Grupo X3</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
