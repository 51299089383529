<!-- <section
  class="section lightweight-template"
  style="background-color: #f9fbfd !important;"
>
  <div class="container">
    <div class="row align-items-center text-center text-lg-left">
      <div class="col-md-2 col-lg-12 mr-auto">
        <h2>HORA aporta valor a su empresa</h2>

        <p class="lead text-secondary">
          El valor que aporta HORA a su empresa se demuestra desde el primer día
          tras la implantación. Una vez realizada nuestra demostración de la
          aplicación podrá optimizar los recursos de manera ordenada, cumpliendo
          con la normativa
        </p>
      </div>
      <div class="col-md-8 col-lg-6 mr-auto">
        <iframe
          width="100%"
          height="357"
          src="https://www.youtube.com/embed/hJ6pBrlPgiM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section> -->
