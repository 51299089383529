<header
  class="page header text-contrast  "
  style="background-image: url('assets/img/bg/price.jpg'); background: #00365F;"
>
  <!-- <div class="divider-shape">
    waves divider
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
      class="shape-waves"
      style="left: 0; transform: rotate3d(0,1,0,180deg);"
    >
      <path
        class="shape-fill"
        [ngClass]="getShapeFill()"
        d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
      />
    </svg>
  </div> -->

  <div
    class="container"
    [ngClass]="containerClass"
    style="padding-top: 33px !important; "
  >
    <div class="row">
      <div class="col-md-{{ rows || 6 }}">
        <h1
          class="regular display-4 text-contrast"
          [ngClass]="{ 'mb-4': subtitle }"
        >
          {{ title }}
        </h1>
        <p class="lead text-contrast" *ngIf="subtitle">{{ subtitle }}</p>
      </div>
    </div>
  </div>
</header>
