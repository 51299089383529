<section
  class="section {{ background ? 'bg-' + background : '' }} "
  [ngClass]="classes"
>
  <div class="container bring-to-front">
    <div class="col">
      <div class="row-md-4 mb-6 text-center">
        <h2>¿En qué podemos ayudarte?</h2>

        <p class="lead">
          Cuéntanos tu proyecto y te ayudaremos a mejorarlo
        </p>

        <!-- <p class="text-muted">
          ¿Aún se pregunta por qué necesita adquirir los servicios de HORAX3?
        </p> -->
      </div>

      <div class="row-md-8">
        <div class="accordion accordion-clean">
          <dc-faqs-accordion></dc-faqs-accordion>
        </div>
      </div>
    </div>
  </div>
</section>
