import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-features-icons-col',
  templateUrl: './features-icons-col.component.html',
  styleUrls: ['./features-icons-col.component.scss']
})
export class FeaturesIconsColComponent implements OnInit {
  elements = [
    {
      icon: 'alert-circle',
      // icon: '../../../assets/img/logos-grupo/alert.png',
      title: 'Cumplimiento',
      mensaje:
        'Cumpla con las exigencias legales incluido el RDL 8/2019 para el registro laboral, facilitando la comunicación entre la plantilla y la empresa.'
    },
    {
      icon: 'sliders',
      title: 'Módulos',
      mensaje:
        'Incluye los módulos necesarios para la gestión de recursos humanos. Puede personalizarse según las necesidades de cada departamento.'
    },
    {
      icon: 'alert-triangle',
      title: 'Seguridad',
      mensaje:
        'Guarde la información en la nube de forma segura, cumpliendo con la normativa de protección de datos UE 2016/679 y LO 3/2018.'
    },
    {
      icon: 'headphones',
      title: 'Soporte',
      mensaje:
        'Siempre contará con la ayuda y la atención personalizada necesarias ante cualquier duda, de esta forma facilitaremos el uso de la aplicación.'
    },
    {
      icon: 'copy',
      title: 'Multidispositivo',
      mensaje:
        'HORAX3 se adapta a las múltiples formas de fichar disponibles, pudiendo llegar a combinarlas y adaptándolas a sus necesidades.'
    }
  ];

  constructor() {}

  ngOnInit() {}
}
