import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dc-slider-start-right-video',
  templateUrl: './slider-start-right-video.component.html',
  styleUrls: ['./slider-start-right-video.component.scss']
})
export class SliderStartRightVideoComponent implements OnInit {
  elements = [
    'Estado Empleados',
    'Calendario',
    'Gestión documental',
    'Informes'
  ];
  longArrowAltRight = faLongArrowAltRight;
  currentSlide: number = 0;

  constructor() {}

  ngOnInit() {}

  updateSlider(currentSlide) {
    this.currentSlide = currentSlide;
  }

  public onIndexChange(index: number): void {
    this.currentSlide = index;
  }
}
