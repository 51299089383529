<!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
  <ngb-panel *ngFor="let faq of faqs">
    <ng-template ngbPanelHeader class="mb-3">
      <button class="card-title btn" ngbPanelToggle>
        <fa-icon [icon]="chevronDown"  class="angle"></fa-icon>
        {{ faq.question }}
      </button>
    </ng-template>

    <ng-template ngbPanelContent>
      <div [innerHTML]="faq.answer"></div>
    </ng-template>
  </ngb-panel>
</ngb-accordion> -->
<!-- <div class="row" (click)="desplegarRespuesta(i)"  > -->
<!-- <div class="contenido" *ngFor="let faq of faqs; let i = index">
  
  <div (click)="desplegarRespuesta(i)" [ngClass]="{ 'row': !faq.estado, 'row2': faq.estado}" >
    <div class="col-10">
      <div class="texto">
        {{ faq.question }}
      </div>
    </div>
    <div class="col-2">
      <div class="icono">
        <fa-icon [icon]="chevronDown" *ngIf="!faq.estado"></fa-icon>
        <fa-icon [icon]="chevronUp" *ngIf="faq.estado"></fa-icon>
      </div>
    </div>
  </div>
  <div *ngIf="faq.estado">
    <div [innerHTML]="faq.answer" [ngClass]="{ clase2: faq.estado }"></div>
  </div>
</div> -->

<div class="contenido" *ngFor="let faq of faqs; let i = index">
  <!-- <div class="row" (click)="desplegarRespuesta(i)"  > -->

  <div
    (click)="desplegarRespuesta(i)"
    class="row"
    *ngIf="!faq.estado"
    style="cursor:pointer"
  >
    <div class="col-10 lead" *ngIf="!faq.estado">
      {{ faq.question }}
    </div>
    <!-- <div class="col-10 texto" *ngIf="faq.estado" style="color: #DCC59C;">
      {{ faq.question }} No aparece
    </div> -->

    <div class="col-2 icono">
      <fa-icon [icon]="chevronDown" *ngIf="!faq.estado"></fa-icon>
      <fa-icon
        [icon]="chevronUp"
        *ngIf="faq.estado"
        style="color: #00365F;"
      ></fa-icon>
    </div>
  </div>

  <div
    (click)="desplegarRespuesta(i)"
    class="row"
    *ngIf="faq.estado"
    style="border: none; cursor: pointer;"
  >
    <!-- <div class="col-10 texto" *ngIf="!faq.estado">
      {{ faq.question }}No aparece
    </div> -->
    <!-- <div class="col-10 texto" *ngIf="faq.estado" style="color: #DCC59C;"> -->
    <div class="col-10 lead" *ngIf="faq.estado" style="color: #00365F;">
      {{ faq.question }}
    </div>

    <div class="col-2 icono">
      <fa-icon [icon]="chevronDown" *ngIf="!faq.estado"></fa-icon>
      <fa-icon
        [icon]="chevronUp"
        *ngIf="faq.estado"
        style="color: #00365F;"
      ></fa-icon>
    </div>
  </div>
  <div *ngIf="faq.estado">
    <div [innerHTML]="faq.answer" [ngClass]="{ clase2: faq.estado }"></div>
  </div>
</div>
