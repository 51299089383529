<section class="perspective-mockups hidden-preload">
  <div class="tablet ipad landscape">
    <div class="screen">
      <img src="assets/img/screens/tablet/1.png" alt="..." />
    </div>
    <div class="button"></div>
  </div>

  <div class="phone-big iphone-x">
    <div class="screen">
      <img src="assets/img/screens/app/1.png" alt="..." />
    </div>
    <div class="notch"></div>
  </div>

  <div class="phone-small iphone-x">
    <div class="screen">
      <img src="assets/img/screens/app/2.png" alt="..." />
    </div>
    <div class="notch"></div>
  </div>

  <div class="tablet">
    <div class="screen">
      <img
        style="width: 520px !important;"
        src="assets/img/screens/tablet/2.png"
        alt="..."
      />
    </div>
    <div class="button"></div>
  </div>
</section>
