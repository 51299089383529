<section class="singl-testimonial shadow">
  <div class="container-fluid py-0">
    <div
      class="row align-items-center gradient gradient-blue-navy text-contrast"
    >
      <div class="col-md-3 mx-auto py-4 py-md-0">
        <p
          class="rounded-pill py-2 px-4 text-uppercase mb-0 badge badge-contrast"
        >
          HORAX3 es excelente para
        </p>

        <p class="font-md bold mt-1 text-contrast">
          Cualquiera que esté buscando una herramienta para el registro laboral
        </p>

        <hr class="my-4" />
        <div class="small text-contrast">
          <label class="bold d-block">Descubra lo que puede hacer por ti</label>
        </div>
      </div>

      <div>
        <iframe
          width="720"
          height="400"
          src="https://www.youtube.com/embed/fgmnYQzzpNM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- <iframe width="720" height="400" src="https://www.youtube.com/embed/P6L3mCLCmho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</section>
