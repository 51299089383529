<section class="section">
  <div class="container bring-to-front">
    <div
      class="shadow rounded text-center p-5"
      style="background: #00365F; border: 10px solid #DCC59C;"
    >
      <!-- <h2 class="mb-5 text-contrast"> -->
      <h2 class="highlight2 text-alternate">
        Agilice el cumplimiento <br />
        en su negocio
      </h2>
      <!-- <p class="handwritten highlight font-md text-alternate"> -->
      <p class="handwritten highlight font-md text-alternate">
        Pruebe HORAX3
      </p>

      <a href="/auth/register" class="btn btn-darker bold mt-5"
        >Solicitar demo</a
      >
    </div>
  </div>
</section>
