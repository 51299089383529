<section id="features" class="section" style="padding-top: 0px !important;">
  <div class="container">
    <div class="section-heading mb-6 text-center">
      <h3 class="bold mt-3" style="margin-bottom: 30px;">
        HORAX3 aporta valor a su empresa
      </h3>
      <!-- <p class="lead text-secondary"> -->
      <p>
        El valor que aporta HORAX3 a su empresa se demuestra desde el primer día
        tras la implantación. Una vez realizada nuestra demostración de la
        aplicación podrá optimizar los recursos de manera ordenada, cumpliendo
        con la normativa.
      </p>
    </div>

    <div class="row gap-y text-center text-md-left">
      <div
        class="col-md-4 py-4 rounded shadow-hover"
        *ngFor="let element of elements.slice(0, 3); let i = index"
      >
        <div *ngIf="i < 3">
          <div *ngIf="i == 0">
            <img
              src="../../../assets/img/logos-grupo/Cumplimiento.png"
              style="width: 57px;"
            />
          </div>
          <div *ngIf="i == 1">
            <img
              src="../../../assets/img/logos-grupo/Modulos.png"
              style="width: 59px;"
            />
          </div>
          <div *ngIf="i == 2">
            <img
              src="../../../assets/img/logos-grupo/Seguridad.png"
              style="width: 64px;"
            />
          </div>
          <!-- <dc-feather
            [name]="element.icon"
            [iconClass]="'stroke-primary'"
          ></dc-feather> -->
          <h5 class="bold mt-3" style="margin-bottom: 30px;">
            {{ element.title }}
          </h5>
          <p>
            {{ element.mensaje }}
          </p>
        </div>
      </div>
      <div class="tarjetas">
        <div
          class="col-md-4 py-4  rounded shadow-hover"
          *ngFor="let element of elements.slice(3); let i = index"
        >
          <!-- <dc-feather
            [name]="element.icon"
            [iconClass]="'stroke-primary'"
          ></dc-feather> -->
          <div *ngIf="i == 0">
            <img
              src="../../../assets/img/logos-grupo/Soporte.png"
              style="width: 53px;"
            />
          </div>
          <div *ngIf="i == 1">
            <img
              src="../../../assets/img/logos-grupo/Multidispositivo.png"
              style="width: 58px;"
            />
          </div>

          <h5 class="bold mt-3" style="margin-bottom: 30px;">
            {{ element.title }}
          </h5>
          <p>
            {{ element.mensaje }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
