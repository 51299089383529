<!-- <section
  class="section {{ background ? 'bg-' + background : '' }} {{
    edge ? 'edge ' + edge : ''
  }}"
>
  <div class="container pt-5">
    <div class="d-flex align-items-center flex-column flex-md-row">
      <div class="text-center text-md-left">
        <p class="light mb-0 text-primary lead">¿Listo para comenzar?</p>
        <h2 class="mt-0">Creese una cuenta ahora</h2>
      </div>
      <a
        [routerLink]="['/auth/register']"
        class="btn btn-primary bold mt-3 mt-md-0 ml-md-auto"
        >Crear cuenta</a
      >
    </div>
  </div>
</section> -->
